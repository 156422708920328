<template>
    <div class="h-full">
        <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource" 
            @onChangeDetailDataSource="onChangeDetailDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay" class="detail">
            <EditControl ref="editApiContrl" :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                    dataSourceKey:null,
                    isAdd: false,
                    isCanEdit:false,
                },
                editDataSource: {},
                initialDataSource: {}
            }
        },
        methods: {
            onChangeEditDataSource: function (dataSourceKey) {
                this.config.dataSourceKey = null;
                this.config.dataSourceKey = dataSourceKey;
                this.config.isAdd=true;
                this.config.isCanEdit=true;
                console.log(dataSourceKey)
                if(!dataSourceKey || dataSourceKey<=0){
                    this.$refs.editApiContrl.add();
                }
            },
            onChangeDetailDataSource: function (dataSourceKey) {
                this.config.dataSourceKey = null;
                this.config.dataSourceKey = dataSourceKey;
                this.config.isAdd=false;
                this.config.isCanEdit=true;
                console.log(this.config.dataSourceKey)
            },
            syncDataSource(){
                this.initialDataSource = this.Utils.extend({},this.editDataSource);
            },
            checkSaveStatus() {
                this.config.isDetailDisplay = false;
            }
        },
        components: {
            "EditControl": resolve => { require(['./components/edit.vue'], resolve) },
            "Control": resolve => { require(['./components/project.vue'], resolve) },
        }
    }
</script>

<style>
</style>